
import config from "../config";
import axios from 'axios';
import {message} from "antd"
import moment from "moment-timezone";

axios.interceptors.request.use((config)=>{
    config.headers["PAY-SESSION-ID"] = localStorage.getItem("PAY-SESSION-ID");
    config.headers["tz"] = moment().format("Z");
    return config;
});

axios.interceptors.response.use(
    (response)=>{
        // console.log("::response::",response);
        const {data,headers} = response;
        if(headers["pay-session-id"]){
            localStorage.setItem("PAY-SESSION-ID",headers["pay-session-id"]);
        }
        if(data.code && data.code != 200){
            message.error(data.msg?data.msg:'请求异常...');
        }
        if(data.code && data.code === 401){
            window.location.href = "/#/login"
        }
        if(data.code && data.code === 402){
            window.location.href = `/#/${response.headers["bind-google-code-name"]}/bindGoogleCode`
        }
        return response;
    },
    (error)=>{
        
        if(error.response){
              return Promise.reject(error);
        }
        message.error("连接超时...");
        return Promise.reject(error);
      
    }
);


/**
 * axios 
 */
export const request = async (options)=>{
    options.withCredentials = true;
    options.url = `${config.baseApiUrl}${options.url}`;
    return axios(options).then(data=>data?data.data:{});

}

export const axiosRequest = async(options)=>{
    return axios(options);
}

export const api = (service)=>{
    
    return {
        get:async (params)=>{
            return request({
                url:`/${service}`,
                params:params
            })
        },

        delete:async (id)=>{
            return request({
                method:"DELETE",
                url:`/${service}/${id}`
            })
        },
        post:async (data)=>{
            return request({
                url:`/${service}`,
                method:"POST",
                data:data
            })
        },
        postForm:async (params)=>{
            return request({
                url:`/${service}`,
                method:"POST",
                params
            })
        },
        save:async(data)=>{
            return request({
                url:`/${service}`,
                method:data.id?"PUT":"POST",
                data:data
            })
        },
        query:async (url,params)=>{
            return request({
                url:`/${service}${url?url:""}`,
                params:params
            })
        },
        list:async (params)=>{
            return request({
                url:`/${service}/list`,
                params:params
            })
        },
        all:async ()=>{
            return request({
                url:`/${service}/all`,
            })
        }
    }
}