
/**
 * 配置
 */
const CONFIG = {
    development: {
        baseApiUrl: "//127.0.0.1:5080",
        // baseApiUrl: "//ba-api.bbaxyz.pw",
        baseImageUrl: "//127.0.0.1:5080"
    },
    production: {
        baseApiUrl: "//ba-api.bbaxyz.pw",
        baseImageUrl: "//ba-api.bbaxyz.pw"
    }

}

console.log(process.env.NODE_ENV);

const config = CONFIG[`${process.env.NODE_ENV}`]

export default config;


const SYS_CONFIG = {
    sysName: "",
    sysLogo: ""
};

export const getSysConfig = () => {
    return SYS_CONFIG;
};

export const initSysConfig = (values) => {

    Object.keys(values).forEach(k => SYS_CONFIG[`${k}`] = values[`${k}`]);

    // document.title = values["sysName"];
    // document.getElementById("logo-ico").setAttribute("href", `${config.baseImageUrl}/${values["sysLogo"]}`)

    return SYS_CONFIG;
}

