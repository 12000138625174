import asyncComponent from "../components/AsyncComponent";
export default [
    {
        path: "/login",
        title: "登录",
        component: asyncComponent(() => import("../pages/login"))
    },
    {
        path:"/:user/bindGoogleCode",
        title:"绑定谷歌验证",
        component:asyncComponent(() => import("../pages/login/bindGoogleCode"))
    },
    {
        path:"/client",
        title:"设备终端",
        component:asyncComponent(()=>import("../pages/client/index"))
    },
    {
        path:"/client-receive",
        title:"收款-设备终端",
        component:asyncComponent(()=>import("../pages/client/receive"))
    },
    {
        path:"/client-payout",
        title:"代付",
        component:asyncComponent(()=>import("../pages/client/payout"))
    },
    {
        path:"/autoreceive",
        title:"自动收款归集",
        component:asyncComponent(()=>import("../pages/client/auto-receive"))
    },
    {
        path:"/payout/voucher/:orderNo",
        title:"代付凭证",
        component:asyncComponent(()=>import("../pages/withdraw/voucher"))
    },
    {
        path: "/",
        title:"管理后台",
        component:asyncComponent(()=>import("../layout/BaLayout"))
        
    }



]