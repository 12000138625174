import React, { Component } from "react";
import { Spin, Icon, Layout} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
export default class AuthLoading extends Component {


    componentDidMount() {



    }

    render() {

        return (
            <Layout style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100%", flex: 1, backgroundColor: "#fff" }}>
               
               <Spin indicator={<LoadingOutlined style={{fontSize:32}}/>}></Spin>
                    {this.props.children}
            </Layout>
        )
    }

}