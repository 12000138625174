import React, { createContext, useContext, useState } from "react";
import moment from "moment-timezone";

const defaultTimezone = localStorage.getItem("moment-tz-timezone");

if(defaultTimezone){
    moment.tz.setDefault(defaultTimezone);
}


// console.log("s=>",moment().format("Z"));


const TimezoneContext = createContext({
    timezone: null,
    setTimezone: () => { }
});

export const useTimezone = () => {

    const contenxt = useContext(TimezoneContext);


    return contenxt;

}



const TimezoneProiver = ({ children }) => {

    const [timezone, setTimezone] = useState(defaultTimezone ? defaultTimezone : moment.tz.guess());

    const updateTimezone = (t) => {

        // console.log("t=>",t);

        localStorage.setItem("moment-tz-timezone", t);

        moment.tz.setDefault(t);

        setTimezone(t);

    }



    return (
        <TimezoneContext.Provider key={timezone} value={{ timezone: timezone, setTimezone: updateTimezone }}>
            {children}
        </TimezoneContext.Provider>
    )

}


export default TimezoneProiver;